import { graphql } from "gatsby"
import React, { FunctionComponent, useEffect, useState } from "react"
import deparam from 'can-deparam'
import Layout from "../../components/Layout"
import Seo from "../../components/Seo"
import Pill from "../../components/Pill"
import Container from "../../components/Container"
import styles from "./index.module.css"
import NotifyForm from "../../components/NotifyForm"
import Callout from "../../components/home/Callout"

interface QueryResult {
  mdx: {
    frontmatter: {
      title: string
      seo: {
        title: string
        description: string
      }
    }
  }
}

interface Props {
  data: QueryResult
}

const timeout = () => new Promise(resolve => {
  setTimeout(resolve, 1000)
})

const injectCalendly = () => {
  if (typeof window !== `undefined`) {
    const document = window.document
    if (!!document.querySelector('#calendly')) return false
    const script = document.createElement('script')
    script.id = 'calendly'
    script.type = 'text/javascript'
    script.src = 'https://assets.calendly.com/assets/external/widget.js'
    script.async = true
    document.getElementsByTagName('head')[0].appendChild(script)

    return true
  }
  return false
}

const waitForCalendly = () => new Promise (async (resolve) => {
  if (typeof window !== `undefined`) {
    const prefill = deparam(window.location.search)


    // @ts-ignore
    if (!window.Calendly) {
      await timeout()
      const calendly = await waitForCalendly()
      return resolve(calendly)
    }
    // @ts-ignore
    return resolve({ calendly: window.Calendly, prefill })
  }
  return resolve(null)
})

const calendlyEventListener = (callback) => {
  if (typeof window !== `undefined`) {
    const isCalendlyEvent = (e) => e.data.event && e.data.event.includes('calendly')

    window.addEventListener(
      'message',
      (e) => {
        if (isCalendlyEvent(e) && e.data.event === 'calendly.event_scheduled') {
          callback(true)
        }
      }
    )
  }
}

const BookADemoPage: FunctionComponent<Props> = ({ data }) => {
  const [booked, setBooked] = useState(false)
  const page = data.mdx.frontmatter

  useEffect(() => {
    const injected = injectCalendly()

    if (injected) {

      waitForCalendly().then(({ calendly, prefill }) => {
        if (!calendly) return false
        calendly.initInlineWidget({
          url: 'https://calendly.com/breezy-sales/01-getting-to-know-each-other?hide_landing_page_details=1&hide_gdpr_banner=1',
          parentElement: document.getElementById('calendlyWidget'),
          prefill
        })

        calendlyEventListener(() => setBooked(true))
      })
    }
  },[])

  return (
    <Layout>
      <Seo
        description={page.seo.description}
        pathname="/book-a-demo"
        title={page.seo.title}
      />

      <div className={styles.root}>


        <header className={`hero ${styles.root}`}>
          <Pill bgClass="bg-black" variant="bottom-right" >
            <div className="pb-24 pt-24 relative z-10">
              <div className="container flex flex-col flex-no-wrap items-center mx-auto text-center text-white">
                <div className="mb-4" style={{ maxWidth: "50ch" }}>
                  <h1>Book a demo</h1>
                </div>

                <div style={{ maxWidth: "65ch"}}>
                  <p className="md:text-4">Do you want a list of highly targeted partnership prospects? To see what Breezy can do for your business (and get some free qualified leads out of it), just book a no-obligation demo.</p>
                </div>

                {/*<NotifyForm />*/}

                {/*<div className="mt-2">*/}
                {/*  <Callout>*/}
                {/*<span className="inline-block text-3">*/}
                {/*  <strong className="font-black">Get your<br/> 14-day free trial</strong>.*/}
                {/*</span>*/}
                {/*  </Callout>*/}
                {/*</div>*/}
              </div>
            </div>
          </Pill>
        </header>
        <div className="container mt-12 flex flex-col flex-no-wrap items-center mx-auto text-center">
          <div style={{ maxWidth: "65ch"}} className="text-left">
            <ul className="list-checked">
              <li>Run highly tailored Partner Searches</li>
              <li>View detailed data snapshots on every Lead</li>
              <li>Organise Leads quickly and easily with advanced filters and folders</li>
              <li>Generate thousands of qualified Leads</li>
            </ul>
            <p className="md:text-4">We can’t wait to show you how Breezy can save you a ton of time on your partner discovery – time you could be spending making those partnerships a success! Book a demo now.</p>
          </div>
        </div>


        <div className="container flex flex-col flex-no-wrap items-center mx-auto text-center relative z-10 mt-12 md:mt-0" style={{minHeight: 920}}>
          <div id="calendlyWidget" style={{width: '100%', height: 900}}/>
        </div>

      </div>
    </Layout>
  )
}

export const query = graphql`
  query BookADemo {
    mdx(fileAbsolutePath: { regex: "//static/book-a-demo-index/" }) {
      frontmatter {
        title
        seo {
          description
          title
        }
      }
    }
  }
`

export default BookADemoPage
